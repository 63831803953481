import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { Montserrat } from 'next/font/google';
import 'styles/variables.scss';
import 'styles/breakpoints.scss';
import 'styles/containers.scss';
import 'styles/typography.scss';
import 'styles/base.scss';
import 'styles/fonts.scss';
import { AppProvider } from 'Providers/App';
import usePageView from 'Utils/hooks/usePageView';
import useFocusAlwaysVisible from 'Utils/hooks/useFocusAlwaysVisible';
import useAnchorFocusHandler from 'Utils/hooks/useAnchorFocusHandler';
import useAB from 'Utils/hooks/useAB';

const montserrat = Montserrat({
  weight: ['300', '400', '500', '600', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  fallback: ['sans-serif'],
  variable: '--font-montserrat',
});

const EVENT_CHATBOT = {
  event: 'data-design-visible',
  'data-design-type': 'module',
  'data-design-nori_id': 'Chatbot',
  'data-design-module_name': 'Nurse Navigator',
  'data-design-module_no': '00',
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const ABresult = useAB();
  useAnchorFocusHandler();
  useFocusAlwaysVisible();

  useEffect(() => {
    if (!window.dataLayer && ABresult) {
      return;
    }

    const eventIndex = window.dataLayer.findIndex(
      (item) =>
        item.event === EVENT_CHATBOT.event &&
        item['data-design-nori_id'] === EVENT_CHATBOT['data-design-nori_id']
    );

    const eventItem = {
      ...EVENT_CHATBOT,
      'data-design-ab_test': ABresult === 'B' ? 'Show' : 'Hide',
    };

    if (eventIndex === -1) {
      window.dataLayer.push(eventItem);
    } else {
      window.dataLayer[eventIndex] = { ...eventItem };
    }
  }, [ABresult]);

  usePageView();

  return (
    <div className={montserrat.variable}>
      <AppProvider>
        <Script
          id="jquery"
          src="https://code.jquery.com/jquery-3.7.1.min.js"
          integrity="sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo="
          crossOrigin="anonymous"
        />

        <Component {...pageProps} />
      </AppProvider>
    </div>
  );
}

export default MyApp;
