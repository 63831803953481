import { useEffect } from 'react';
import { useRouter } from 'next/router';

const PAGE_SECTION_DEFAULT = 'n/a';
const PAGE_SECTION_HOME = '/';
const PAGE_SECTION_HOW_IT_WORKS = 'how-it-works';
const PAGE_SECTION_SAFETY = 'safety-and-efficacy';
const PAGE_SECTION_COST_COVERAGE = 'cost-and-coverage';
const PAGE_SECTION_FAQ = 'faq';
const PAGE_SECTION_MY_NEXPLANON = 'my-nexplanon';
const PAGE_SECTION_ES_US_HOME = 'es-us';
const PAGE_SECTION_ES_US_FAQ = 'es-us/preguntas-frecuentes';
const PAGE_SECTION_ES_US_EFECTOS = 'es-us/efectos-secundarios-y-riesgos';

const PAGE_SUB_SECTION = {
  default: PAGE_SECTION_DEFAULT,
  '/': PAGE_SECTION_HOME,
  '/what-is-nexplanon/': PAGE_SECTION_HOW_IT_WORKS,
  '/how-does-nexplanon-work/': PAGE_SECTION_HOW_IT_WORKS,
  '/birth-control-options/': PAGE_SECTION_HOW_IT_WORKS,
  '/insertion/': PAGE_SECTION_HOW_IT_WORKS,
  '/removal/': PAGE_SECTION_HOW_IT_WORKS,
  '/replacing-implant/': PAGE_SECTION_HOW_IT_WORKS,
  '/pregnancy-after-nexplanon/': PAGE_SECTION_HOW_IT_WORKS,
  '/five-fast-facts/': PAGE_SECTION_HOW_IT_WORKS,
  '/effectiveness/': PAGE_SECTION_SAFETY,
  '/side-effects/': PAGE_SECTION_SAFETY,
  '/period-changes/': PAGE_SECTION_SAFETY,
  '/calling-your-doctor/': PAGE_SECTION_SAFETY,
  '/medicine-interactions/': PAGE_SECTION_SAFETY,
  '/cost/': PAGE_SECTION_COST_COVERAGE,
  '/insurance-coverage/': PAGE_SECTION_COST_COVERAGE,
  '/questions/': PAGE_SECTION_FAQ,
  '/enrollment/': PAGE_SECTION_MY_NEXPLANON,
  '/es-us/': PAGE_SECTION_ES_US_HOME,
  '/es-us/efectos-secundarios/': PAGE_SECTION_ES_US_EFECTOS,
  '/es-us/preguntas/': PAGE_SECTION_ES_US_FAQ,
};

export const PAGE_VIEW_CONFIG = {
  pageEvent: 'pageview',
  gtmloadEvent: 'gtm.load',
  onetrustLoadedEvent: 'OneTrustLoaded',
  routeChangeEvent: 'routeChangeComplete',
  loggedEvent: 'Logged In',
  notLoggedEvent: 'Not Logged In',
};

export const pageview = (url, isLoggedIn = false) => {
  window.dataLayer.push({
    event: PAGE_VIEW_CONFIG.pageEvent,
    page: url,
    site_section: PAGE_SUB_SECTION[url] || PAGE_SUB_SECTION.default,
    loginStatus: isLoggedIn
      ? PAGE_VIEW_CONFIG.loggedEvent
      : PAGE_VIEW_CONFIG.notLoggedEvent,
  });

  window.dataLayer.push({
    event: PAGE_VIEW_CONFIG.gtmloadEvent,
  });
};

export const OneTrustLoadedEvent = () => {
  if (window.location.search === '') {
    window.dataLayer.push({
      event: PAGE_VIEW_CONFIG.onetrustLoadedEvent,
      OnetrustActiveGroups: window.OnetrustActiveGroups,
    });
  }
};

export const usePageView = () => {
  const router = useRouter();

  useEffect(() => {
    if (!window.dataLayer) {
      return;
    }

    router.events.on(PAGE_VIEW_CONFIG.routeChangeEvent, OneTrustLoadedEvent);

    return () => {
      router.events.off(PAGE_VIEW_CONFIG.routeChangeEvent, OneTrustLoadedEvent);
    };
  }, [router.events]);

  return;
};

export default usePageView;
