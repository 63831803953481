import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  FIXED_ELEMENT_IDS,
  FOCUS_CONFIG,
  DEFAULT_RECT_ELEMENT,
  getFixedElementRect,
} from 'Utils/hooks/useFocusAlwaysVisible';

const TARGET_FOCUSABLE_TABINDEX = 0;
const TARGET_ID_INDEX = 0;
const TARGET_PAGE_INDEX = 0;
const TARGET_ID_SLICE = -1;

export const useAnchorFocusHandler = () => {
  const { pathname } = useRouter();

  useEffect(() => {
    const fixedDesktopElement = document.getElementById(
      FIXED_ELEMENT_IDS.header
    );

    const fixedMobileElement = document.getElementById(
      FIXED_ELEMENT_IDS.isiMobile
    );

    const handleAnchorClick = (e) => {
      const hrefSplitted = e.target.getAttribute('href').split('#');
      const currentPathname = (pathname + '/').replaceAll('//', '') || '/';

      if (hrefSplitted[TARGET_PAGE_INDEX] !== currentPathname) {
        return;
      }

      e.preventDefault();
      const targetId = hrefSplitted.slice(TARGET_ID_SLICE)[TARGET_ID_INDEX];
      const targetElement = document.getElementById(targetId);

      if (!targetElement) {
        return;
      }

      const fixedElementRect = getFixedElementRect(
        fixedDesktopElement,
        fixedMobileElement,
        window.innerWidth > FOCUS_CONFIG.breakpoint
      );

      const fixedElementHeight =
        fixedElementRect.height || DEFAULT_RECT_ELEMENT.height;
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - fixedElementHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'auto',
      });

      targetElement.setAttribute('tabIndex', TARGET_FOCUSABLE_TABINDEX);
      targetElement.dataset.targetElement = true;
      targetElement.focus({ preventScroll: true });
      targetElement.removeAttribute('tabIndex');
    };

    const anchors = Array.from(document.querySelectorAll('a[href*="#"]'));
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [pathname]);
};

export default useAnchorFocusHandler;
